import React from 'react';
import { connect } from 'react-redux';

const getStyle = (props) => {
    let className = '';
    if (props.difficulty) { className = "border" }
    if (props.isSetDifficulty) { className += " bg-light" }

    return className;
}

const Robot = (props) => {
    let multiply = props.multiply;
    const isMobile = window.innerWidth <= 850;
    if (props.resize && isMobile) { multiply /= 2 }

    let baseHead = {
        backgroundColor: props.color || '#a4c739',
        width: (400 * multiply) + 'px',
        height: (200 * multiply) + 'px',
        marginBottom: (10 * multiply) + 'px'
    }

    let baseUpperBody = {
        width: (620 * multiply) + 'px',
        height: (400 * multiply) + 'px'
    }

    let baseArm = {
        backgroundColor: props.color || '#a4c739',
        width: (100 * multiply) + 'px',
        height: (325 * multiply) + 'px',
        borderRadius: (100 * multiply) + 'px'
    }

    let leftArm = {
        marginRight: (10 * multiply) + 'px'
    }

    let baseTorso = {
        backgroundColor: props.color || '#a4c739',
        width: (400 * multiply) + 'px',
        height: (400 * multiply) + 'px',
        marginRight: (10 * multiply) + 'px'
    }

    let baseLowerBody = {
        width: (400 * multiply) + 'px',
        height: (200 * multiply) + 'px'
    }

    let leftAndRightLeg = {
        backgroundColor: props.color || '#a4c739',
        bottom: isMobile ? '6px' : (2.1 / multiply) + 'px',
        width: (100 * multiply) + 'px',
        height: (200 * multiply) + 'px'
    }

    let leftLeg = {
        marginLeft: (75 * multiply) + 'px'
    }

    let rightLeg = {
        marginLeft: (50 * multiply) + 'px'
    }

    let baseEye = {
        width: (30 * multiply) + 'px',
        height: (30 * multiply) + 'px',
        borderRadius: (15 * multiply) + 'px',
        top: (100 * multiply) + 'px',
        backgroundColor: props.eyeColor ? props.eyeColor : 'white'
    }

    let leftEye = {
        left: (90 * multiply) + 'px'
    }

    let rightEye = {
        left: (250 * multiply) + 'px'
    }

    let baseAntenna = {
        width: (400 * multiply) + 'px',
        height: (100 * multiply) + 'px'
    }

    let leftAndRightAntenna = {
        backgroundColor: props.color || '#a4c739',
        width: (10 * multiply) + 'px',
        height: (100 * multiply) + 'px',
        borderRadius: (10 * multiply) + 'px',
        top: (40 * multiply) + 'px'
    }

    let leftAntenna = {
        left: (70 * multiply) + 'px'
    }

    let rightAntenna = {
        left: (310 * multiply) + 'px'
    }

    return (
        <div id="android" className={getStyle(props)} onClick={() => props.onClick && props.onClick()}>
            <div id="antenna" style={baseAntenna} >
                <div id="left_antenna" style={Object.assign({}, leftAndRightAntenna, leftAntenna)}>&nbsp;</div>
                <div id="right_antenna" style={Object.assign({}, leftAndRightAntenna, rightAntenna)}>&nbsp;</div>
            </div>
            <div id="head" style={baseHead}>
                <div id="left_eye" style={Object.assign({}, baseEye, leftEye)}>&nbsp;</div>
                <div id="right_eye" style={Object.assign({}, baseEye, rightEye)}>&nbsp;</div>
            </div>
            <div id="upper_body" style={baseUpperBody} >
                <div id="left_arm" style={Object.assign({}, baseArm, leftArm)}>&nbsp;</div>
                <div id="torso" style={baseTorso}>&nbsp;</div>
                <div id="right_arm" style={baseArm}>&nbsp;</div>
            </div>
            <div id="lower_body" style={baseLowerBody} >
                <div id="left_leg" style={Object.assign({}, leftAndRightLeg, leftLeg)}>&nbsp;</div>
                <div id="right_leg" style={Object.assign({}, leftAndRightLeg, rightLeg)}>&nbsp;</div>
            </div>
            {props.name &&
                <h4 className="name">{props.name}</h4>
            }
            {props.difficulty && <div><h3 className={props.isSetDifficulty ? 'text-dark' : ''}>{props.difficulty}</h3></div>}
        </div>
    );

}
export default connect()(Robot);
