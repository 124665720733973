import React from 'react';

const style = (guess) => {
    if (guess && guess.submitted) {
        if (guess.correctIndex) {
            return 'correct-index';
        } else if (guess.correctLetter) {
            return 'correct-letter';
        } else {
            return 'submitted'
        }
    } else {
        return ''
    }
}

const DroidleRow = (props) => {
    return (
        <div className="guess-row">
            {
                [...Array(5)].map((_, idx) =>
                    props.user ?
                        <span key={idx} className={props.guess && props.guess[idx] ? style(props.guess[idx]) : ''} onClick={() => props.pressable && props.guess && props.guess[idx] && !props.guess[idx].previouslySetCorrectIndex && props.onClick(idx)}>{props.guess && props.guess[idx] ? props.guess[idx].letter : ''}</span>
                        :
                        <span key={idx} className={props.guess && props.guess[idx] ? style(props.guess[idx]) : ''}>{props.guess && props.guess[idx] && props.winnerDecided ? props.guess[idx].officialLetter : ''}</span>
                )
            }
        </div>
    );
}

export default DroidleRow;

