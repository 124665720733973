import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import createRootReducer from './reducers'

export const history = createBrowserHistory();
const reactRouterMiddleware = routerMiddleware(history);

const middleWares = [
    thunk,
    reactRouterMiddleware
]

export default function configureStore() {
    const store = createStore(
        createRootReducer(history), // root reducer with router state
        compose(applyMiddleware(...middleWares))
    )

    return store
}