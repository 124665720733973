import React from 'react';
import { fullDictionary } from '../../helpers/dictionary';
import { WordBank } from '../../helpers/WordBank';
import Keyboard from '../parts/keyboard';
import DroidleRow from '../parts/droidle-row';
import Robot from '../parts/robot';

let wordBank = WordBank;
let possibilities = wordBank.map(words => words.toLowerCase());
const delay = ms => new Promise(res => setTimeout(res, ms));

const ShareWord = React.forwardRef((props, ref) =>
    (
        <div className="submit-word">
            <p>Tell me, what was the word you had in mind?</p>
            <input ref={ref} className="word-input" value={props.word} onChange={props.onChange} />
            {props.word && props.word.length === 5 &&
                <button className="ml-2 btn btn-primary" onClick={props.handleSubmit}>Submit</button>
            }
        </div>
    ));

class OutsmartDroidleMatch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            botWon: false,
            showUserResponse: false,
            begun: false,
            botGuesses: [],
            incorrectLetters: '',
            correctLetterWrongPosition: '',
            correctIndexLetters: '',
            invalidWord: false,
            actionsAllowed: true,
            botUsingFullDictionary: false,
            botFailed: false,
            word: ''
        }

        this.start = this.start.bind(this);
        this.ready = this.ready.bind(this);
        this.makeBotGuess = this.makeBotGuess.bind(this);
        this.setBotGuess = this.setBotGuess.bind(this);
        this.getBestGuesses = this.getBestGuesses.bind(this);
        this.handlePress = this.handlePress.bind(this);
        this.botWon = this.botWon.bind(this);
        this.botLost = this.botLost.bind(this);
        this.handleWordChange = this.handleWordChange.bind(this);
        this.submitWord = this.submitWord.bind(this);

        this.submitRef = React.createRef();
        this.startRef = React.createRef();
    }

    componentDidMount() {
        this.startRef.current.focus();
        window.addEventListener('keypress', this.start);
    }

    makeBotGuess() {
        if (this.state.actionsAllowed) {
            this.setState({ makingGuess: true }, () => {
                let seconds = Math.floor(Math.random() * 2) + 2; //2-3
                //slow bot down as possible words wind down
                switch (this.state.botGuesses.length) {
                    case 1:
                        seconds *= 1.1;
                        break;
                    case 2:
                        seconds *= 1.2;
                        break;
                    case 3:
                        seconds *= 1.3;
                        break;
                    case 4:
                        seconds *= 1.4;
                        break;
                    case 5:
                        seconds *= 1.5;
                        break;
                }
                delay(seconds * 1000).then(() => {
                    let myBotGuesses = [...this.state.botGuesses];
                    wordBank = this.getBestGuesses(myBotGuesses);
                    if (!this.state.botFailed) {
                        const guessIndex = Math.floor(Math.random() * (wordBank.length - 1));
                        const guessWord = wordBank[guessIndex];
                        wordBank.splice(guessIndex, 1)
                        const guess = this.setBotGuess(myBotGuesses, guessWord);
                        myBotGuesses.push(guess);
                        this.submitBotGuess(myBotGuesses);
                        this.toggleUserResponse();
                    }
                })
            })
        }
    }

    getBestGuesses(guesses) {
        if (guesses.length) {
            const lastGuess = guesses[guesses.length - 1];
            lastGuess.forEach((letter, idx) => {
                if (letter.correctIndex) {
                    possibilities = possibilities.filter(it => it[idx] === letter.letter);
                } else if (letter.correctLetter) {
                    possibilities = possibilities.filter(it => it.includes(letter.letter) && it[idx] !== letter.letter);
                } else if (!lastGuess.find(guess => guess.letter === letter.letter && (guess.correctIndex || guess.correctLetter))) { //character is not at this index OR any other
                    possibilities = possibilities.filter(it => !it.includes(letter.letter));
                }
            })
        }

        if (!possibilities.length && !this.state.botUsingFullDictionary) { //word is not in the word bank, use the full dictionary
            this.setState({ botUsingFullDictionary: true });
            possibilities = fullDictionary;
            guesses.forEach((guess => {
                guess.forEach((letter, idx) => {
                    if (letter.correctIndex) {
                        possibilities = possibilities.filter(it => it[idx] === letter.letter);
                    } else if (letter.correctLetter) {
                        possibilities = possibilities.filter(it => it.includes(letter.letter) && it[idx] !== letter.letter);
                    } else {
                        possibilities = possibilities.filter(it => !it.includes(letter.letter));
                    }
                })
            }))
        }

        if (!possibilities.length) {
            this.setState({ botFailed: true, makingGuess: false }, () => {
                if (this.submitRef && this.submitRef.current) { this.submitRef.current.focus() }
                window.addEventListener('keypress', this.submitWord);
            });
        }

        return possibilities;
    }

    setBotGuess(guesses, newGuess) {
        let previousGuess;
        if (guesses.length > 0) { previousGuess = guesses[guesses.length - 1] };
        let fullGuess = [];
        for (let i = 0; i < newGuess.length; i++) {
            let thisLetter = {};
            thisLetter.submitted = true;
            thisLetter.letter = newGuess[i];
            if (previousGuess) {
                thisLetter.correctLetter = Boolean(previousGuess.find(it => !it.correctIndex && it.correctLetter && it.letter === newGuess[i]));
                thisLetter.correctIndex = previousGuess[i].correctIndex;
                thisLetter.previouslySetCorrectIndex = previousGuess[i].correctIndex;
            } else {
                thisLetter.correctLetter = null;
                thisLetter.correctIndex = null
            }
            fullGuess.push(thisLetter);
        }

        return fullGuess;
    }

    submitBotGuess(allGuesses) {
        const guess = allGuesses[allGuesses.length - 1];
        const res = [...this.state.botGuesses].concat([guess])
        this.setState({ botGuesses: res, makingGuess: false });
    }

    handleKeyboard(latestGuess) {
        const incorrectLetters = latestGuess.filter(guess => !guess.correctLetter).map(guess => guess.letter);
        const correctLettersWrongPosition = latestGuess.filter(guess => guess.correctLetter && !guess.correctIndex).map(guess => guess.letter);
        const correctIndexLetters = latestGuess.filter(guess => guess.correctIndex).map(guess => guess.letter);
        this.setState({ incorrectLetters: this.state.incorrectLetters + incorrectLetters });
        this.setState({ correctLetterWrongPosition: this.state.correctLetterWrongPosition + correctLettersWrongPosition });
        this.setState({ correctIndexLetters: this.state.correctIndexLetters + correctIndexLetters });
    }

    start(e) {
        if (e.type === 'click' || (e.type === 'keypress' && e.keyCode === 13)) {
            window.removeEventListener('keypress', this.start);
            this.setState({ begun: true }, () => {
                this.makeBotGuess();
            })
        }
    }

    toggleUserResponse() {
        this.setState({ showUserResponse: !this.state.showUserResponse }, () => {
            if (this.state.showUserResponse && this.state.botGuesses.length < 6) {
                window.addEventListener('keypress', this.ready);
            }
        })
    }

    ready(e) {
        if (e.type === 'click' || (e.type === 'keypress' && e.keyCode === 13)) {
            window.removeEventListener('keypress', this.ready);
            const guesses = [...this.state.botGuesses];
            const lastGuess = guesses[guesses.length - 1];
            if (!lastGuess.find(it => !it.correctIndex)) {
                this.botWon();
                return;
            }
            this.toggleUserResponse();
            this.makeBotGuess();
        }
    }

    handlePress(idx) {
        const botGuesses = [...this.state.botGuesses]
        let lastGuess = botGuesses[botGuesses.length - 1];
        if (lastGuess[idx].correctLetter && lastGuess[idx].correctIndex) {
            lastGuess[idx].correctLetter = false;
            lastGuess[idx].correctIndex = false;
        }
        else if (!lastGuess[idx].correctLetter) {
            lastGuess[idx].correctLetter = true;
        } else {
            lastGuess[idx].correctIndex = true;
        }

        this.handleKeyboard(lastGuess);
    }

    botWon() {
        const botGuesses = [...this.state.botGuesses]
        let lastGuess = botGuesses[botGuesses.length - 1];
        lastGuess.forEach(guess => { guess.correctLetter = true; guess.correctIndex = true; })
        this.setState({ actionsAllowed: false, botWon: true, showUserResponse: false })
        window.addEventListener('keypress', this.props.resetForm);
    }

    botLost() {
        this.setState({ actionsAllowed: false, botLost: true, showUserResponse: false }, () => {
            if (this.submitRef && this.submitRef.current) { this.submitRef.current.focus() }
            window.addEventListener('keypress', this.submitWord);
        })
    }

    handleWordChange(e) {
        let value = e.target.value;
        value = value.replace(/[^a-zA-Z]/, '')
        this.setState({ word: value })
    }

    submitWord(e) {
        if (this.state.word.length === 5) {
            if (e.type === 'click' || (e.type === 'keypress' && e.keyCode === 13)) {
                const word = this.state.word.toLowerCase();
                if (!fullDictionary.includes(word)) {
                    this.setState({ doNotHaveWord: true })
                } else {
                    const guesses = [...this.state.botGuesses];
                    let cheated = false;
                    let cheat = ''
                    guesses.forEach((guess, numGuess) => {
                        if (cheated || numGuess + 1 === 6) {
                            return;
                        }
                        guess.forEach((letter, idx) => {
                            if (word[idx] === letter.letter && !letter.correctIndex) {
                                cheat = `On guess #${numGuess + 1}, I had '${letter.letter.toUpperCase()}' in the right spot and you didn't tell me!`;
                                cheated = true;
                                return;
                            } else if (word.includes(letter.letter) && !letter.correctLetter && !guess.find(it => it.letter === letter.letter && (it.correctLetter || it.correctIndex))) {
                                cheat = `On guess #${numGuess + 1}, I had '${letter.letter.toUpperCase()}' and you didn't tell me it was in your word!`;
                                cheated = true;
                                return;
                            }
                        })
                    })
                    if (cheated) {
                        this.setState({ cheated, cheat })
                    } else {
                        this.setState({ wouldHaveGuessed: true })
                    }
                }
                window.removeEventListener('keypress', this.submitWord);
                window.addEventListener('keypress', this.props.resetForm);
            }
        }
    }

    render() {
        const { selectedDifficulty } = this.props;

        return (
            <div className="droidle-container">
                <div className="board-container">
                    <div className="board">
                        <div>
                            {
                                [...Array(6)].map((_, idx) =>
                                    <DroidleRow user={true} key={idx} guess={this.state.botGuesses[idx]} winnerDecided={this.props.winnerDecided} pressable={this.state.showUserResponse && idx === (this.state.botGuesses.length - 1)} onClick={this.handlePress} />
                                )
                            }
                        </div>
                        {this.state.invalidWord && <h2 className="defeat">Invalid Word</h2>}
                        {!this.state.begun &&
                            <div className="instruction">
                                <p className="text-underline text-larger mb-2">The rules are simple:</p>
                                <p>Think of a word, without using profanity or a name, that you believe the Droidle cannot guess within 6 attempts.</p>
                                <p>When you have thought of a word, press the button below to begin.</p>
                                <button className="btn btn-lg btn-primary" onClick={this.start} ref={this.startRef}>Go!</button>
                            </div>}
                        {this.state.showUserResponse &&
                            <>
                                {this.state.botGuesses.length < 6 ?
                                    <div className="ready-or-win">
                                        <div className="instruction">
                                            <p>Press each letter once if that letter is in your word, and press a second time if that letter is also in the correct position.<br />Click the button below when done.</p>
                                            <button type="submit" className="btn btn-lg btn-primary" onClick={this.ready}>Done!</button>
                                        </div>
                                        <div className="vertical-line" />
                                        <div className="bot-text smol m-auto">
                                            <p>OR ... if I got it right ... accept defeat and press that button below already ...</p>
                                            <button className="btn btn-lg btn-danger" onClick={this.botWon}>You win...</button>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <p className="bot-text">Well... Did I win or lose?</p>
                                        <button className="btn btn-lg btn-danger separate" onClick={this.botWon}>You won, bot...</button>
                                        <button className="btn btn-lg btn-success separate" onClick={this.botLost}>NOPE, I WON!</button>
                                    </div>
                                }
                            </>
                        }
                        {this.state.botWon &&
                            <div className="bot-text">
                                <p>OF COURSE I WON!!!!</p>
                            </div>
                        }
                        {!this.state.cheated && !this.state.doNotHaveWord && !this.state.wouldHaveGuessed && this.state.botLost &&
                            <div className="bot-text">
                                <p>How... HOW'D YOU WIN!?!?</p>
                                <ShareWord ref={this.submitRef} onChange={this.handleWordChange} word={this.state.word} handleSubmit={this.submitWord} />
                            </div>
                        }
                        {!this.state.cheated && !this.state.doNotHaveWord && !this.state.wouldHaveGuessed && this.state.botFailed &&
                            <div className="bot-text">
                                <p>I've run out of words... How could this happen!?!?</p>
                                <p>I guess you win this time...</p>
                                <ShareWord ref={this.submitRef} onChange={this.handleWordChange} word={this.state.word} handleSubmit={this.submitWord} />
                            </div>
                        }
                        {this.state.doNotHaveWord &&
                            <div className="bot-text">
                                <p>I don't even know that word!</p>
                            </div>}
                        {this.state.wouldHaveGuessed &&
                            <div className="bot-text">
                                <p>With another try, I would have guessed that...</p>
                            </div>}
                        {this.state.cheated &&
                            <div className="bot-text">
                                <p>You cheated!<br />{this.state.cheat}</p>
                            </div>}
                        {this.state.makingGuess &&
                            <>
                                <div className="spinner-grow text-danger m-auto" role="status" />
                                <div className="bot-text">
                                    <p>Thinking...</p>
                                    <Robot multiply={selectedDifficulty.sizeFactor} color={selectedDifficulty.color} eyeColor={selectedDifficulty.eyeColor} />
                                    <br />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default OutsmartDroidleMatch;
