import React from 'react';

const Keyboard = (props) => {
    const keys = props.keys;

    return (
        <div className={`keyboard${!props.user ? ' disabled' : ''}`}>
            {
                keys.map((letters, index) =>
                    <div key={index} className="row">

                        {index === keys.length - 1 &&
                            <button disabled={(props.began && !props.actionsAllowed) || !props.user} className="kbd">
                                <kbd disabled={(props.began && !props.actionsAllowed) || !props.user} onClick={props.handleEnter}>Enter</kbd>
                            </button>
                        }

                        {index === 1 &&
                            <div className="middle-space" />
                        }

                        {letters.split("").map((letter, idx) =>
                            <button key={idx} disabled={(props.began && !props.actionsAllowed) || !props.user} className="kbd">
                                <kbd disabled={(props.began && !props.actionsAllowed) || !props.user} className={props.incorrectLetters.toLowerCase().includes(letter.toLowerCase()) ? 'incorrect' : props.correctIndexLetters.toLowerCase().includes(letter.toLowerCase()) ? 'correct-index' : props.correctLettersWrongPosition.toLowerCase().includes(letter.toLowerCase()) ? 'correct-letter' : ''} onClick={() => props.user && props.clickLetter(letter)}>{letter}</kbd>
                            </button>
                        )}

                        {index === 1 &&
                            <div className="middle-space" />
                        }

                        {index === keys.length - 1 &&
                            <button disabled={(props.began && !props.actionsAllowed) || !props.user} className="kbd">
                                <kbd disabled={(props.began && !props.actionsAllowed) || !props.user} onClick={props.deleteLetter}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                                        <path fill={props.user ? "#ffffff" : "#818384"} d="M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H7.07L2.4 12l4.66-7H22v14zm-11.59-2L14 13.41 17.59 17 19 15.59 15.41 12 19 8.41 17.59 7 14 10.59 10.41 7 9 8.41 12.59 12 9 15.59z"></path>
                                    </svg>
                                </kbd>
                            </button>
                        }
                        <br />
                    </div>
                )
            }
        </div>
    );
}

export default Keyboard;