import React from 'react';
import { fullDictionary } from '../../helpers/dictionary';
import { WordBank } from '../../helpers/WordBank';
import Keyboard from '../parts/keyboard';
import DroidleRow from '../parts/droidle-row';

let wordBank = WordBank;
let possibilities = wordBank.map(words => words.toLowerCase());
const correctWord = wordBank[Math.floor(Math.random() * (wordBank.length - 1))];
const keys = ['QWERTYUIOP', 'ASDFGHJKL', 'ZXCVBNM']
const allKeys = keys.join('');
const delay = ms => new Promise(res => setTimeout(res, ms));

const IOS = "IOS";
const ANDROID = "ANDROID";
const WINDOWSPHONE = "WINDOWSPHONE";

class OneVersusOneMatch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            operatingSystem: this.getMobileOperatingSystem(),
            guesses: [[]],
            botGuesses: [],
            incorrectLetters: '',
            correctLetterWrongPosition: '',
            correctIndexLetters: '',
            invalidWord: false,
            actionsAllowed: false,
            botUsingFullDictionary: false,
            botFailed: false
        }

        this.handleEnter = this.handleEnter.bind(this);
        this.handleKeyboard = this.handleKeyboard.bind(this);
        this.handler = this.handler.bind(this);
        this.botGuessedCorrectly = this.botGuessedCorrectly.bind(this);
        this.clickLetter = this.clickLetter.bind(this);
        this.deleteLetter = this.deleteLetter.bind(this);
        this.getBestGuesses = this.getBestGuesses.bind(this);
        this.getText = this.getText.bind(this);

        this.startRef = React.createRef();

        this._isMounted = false;
    }

    getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return WINDOWSPHONE;
        }

        if (/android/i.test(userAgent)) {
            return ANDROID;
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod|Mac/.test(userAgent) && !window.MSStream) {
            return IOS;
        }

        return "unknown";
    }

    handler(e) {
        if (!e.altKey && !e.ctrlKey && !e.metaKey && this.state.guesses.length <= 6) {
            if (!this.props.began) {
                this.props.start(null, true);
            }
            const key = e.key
            if (this.state.guesses[this.state.guesses.length - 1].length < 5 || key === "Enter" || key === "Backspace") {
                if (allKeys.toLowerCase().includes(key.toLowerCase())) {
                    this.clickLetter(key.toLowerCase());
                } else if (key === "Enter") {
                    this.handleEnter();
                } else if (key === "Backspace") {
                    this.deleteLetter();
                }
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.user) {
            this.startRef.current.focus();
            window.addEventListener('keydown', this.handler);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.began !== this.props.began && this.props.began) {
            if (this.props.user) {
                this.setState({ actionsAllowed: true })
            } else {
                this.setState({ actionsAllowed: true }, async () => {
                    for (let i = 0; i < 6; i++) {
                        if (this.state.actionsAllowed && this._isMounted) {
                            let seconds;
                            if (this.state.botGuesses.length === 0) {
                                seconds = Math.floor(Math.random() * 3) + 2; //2-4
                            } else {
                                switch (this.props.selectedDifficulty.difficulty) {
                                    case 'hard':
                                        seconds = Math.floor(Math.random() * 5) + 10; //10-14
                                        break;
                                    case 'medium':
                                        seconds = Math.floor(Math.random() * 10) + 15; //15-24
                                        break;
                                    case 'easy':
                                        seconds = Math.floor(Math.random() * 5) + 25; //25-29
                                        break;
                                }
                                //slow bot down as possible words wind down
                                switch (this.state.botGuesses.length) {
                                    case 1:
                                        break;
                                    case 2:
                                        seconds *= 1.2;
                                        break;
                                    case 3:
                                        seconds *= 1.4;
                                        break;
                                    case 4:
                                        seconds *= 1.6;
                                        break;
                                    case 5:
                                        seconds *= 1.8;
                                        break;
                                }
                            }
                            await delay(seconds * 1000)
                            this.makeBotGuess();
                        }
                    }
                })
            }
        }
        if (prevProps.winnerDecided !== this.props.winnerDecided && this.props.winnerDecided) {
            this.setState({ actionsAllowed: false })
            if (this.props.user) {
                window.removeEventListener('keydown', this.handler);
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    makeBotGuess() {
        if (this._isMounted && this.state.actionsAllowed) {
            let myBotGuesses = [...this.state.botGuesses];
            wordBank = this.getBestGuesses(myBotGuesses);
            if (!this.state.botFailed) {
                const guessIndex = Math.floor(Math.random() * (wordBank.length - 1));
                const guessWord = wordBank[guessIndex];
                wordBank.splice(guessIndex, 1)
                const guess = this.setBotGuess(guessWord);
                myBotGuesses.push(guess);
                this.submitBotGuess(myBotGuesses);
                if (this.botGuessedCorrectly(myBotGuesses[myBotGuesses.length - 1])) { return; }
            }
        }
    }

    botGuessedCorrectly(botGuess) {
        const guess = botGuess.map(guess => guess.officialLetter).join('');
        const res = guess === correctWord;
        if (res) { this.props.setBotWinner(true) }
        return res
    }

    getBestGuesses(guesses) {
        if (guesses.length) {
            const lastGuess = guesses[guesses.length - 1];
            lastGuess.forEach((letter, idx) => {
                if (letter.correctIndex) {
                    possibilities = possibilities.filter(it => it[idx] === letter.officialLetter);
                } else if (letter.correctLetter) {
                    if (lastGuess.find(guess => guess.officialLetter === letter.officialLetter && guess.correctIndex)) {
                        //if guess is catch, and word is cycle, the last c falls here, and needs to end up saying 'filter possibilities to that which does not have c at this location 
                        //but does have it at other indexes (excluding the first 'c' which was already taken care of in the initial if statement)
                        //which means the final list of indexes should be [1, 2, 4]
                        let indexes = [0, 1, 2, 3, 4];
                        indexes.splice(idx, 1); //letter is not at this index (in example above, array is now [0, 1, 2, 4] and now needs to remove any indexes already correctly guessed)
                        for (let i = 0; i < lastGuess.length; i++) {
                            if (lastGuess[i].correctIndex) {
                                indexes.splice(indexes.indexOf(i), 1);
                            } //if that spot is already correct, ignore it; in aexample above, array is now [1, 2, 4]
                        }

                        possibilities = possibilities.filter(word => {
                            if (word[idx] === letter.officialLetter) { return false; }
                            else {
                                let possibleWord = false;
                                indexes.forEach(anotherDangIndex => {
                                    if (word[anotherDangIndex] === letter.officialLetter) { possibleWord = true; return; }
                                })

                                return possibleWord;
                            }
                        });
                    } else {
                        possibilities = possibilities.filter(it => it.includes(letter.officialLetter) && it[idx] !== letter.officialLetter);
                    }
                } else if (!lastGuess.find(guess => guess.officialLetter === letter.officialLetter && (guess.correctIndex || guess.correctLetter))) { //character is not at this index OR any other
                    possibilities = possibilities.filter(it => !it.includes(letter.officialLetter));
                }
            })
        }

        if (!possibilities.length && !this.state.botUsingFullDictionary) { //word is not in the word bank, use the full dictionary
            this.setState({ botUsingFullDictionary: true });
            possibilities = fullDictionary;
            guesses.forEach((guess => {
                guess.forEach((letter, idx) => {
                    if (letter.correctIndex) {
                        possibilities = possibilities.filter(it => it[idx] === letter.officialLetter);
                    } else if (letter.correctLetter) {
                        possibilities = possibilities.filter(it => it.includes(letter.officialLetter) && it[idx] !== letter.officialLetter);
                    } else {
                        possibilities = possibilities.filter(it => !it.includes(letter.officialLetter));
                    }
                })
            }))
        }

        if (!possibilities.length) {
            this.setState({ botFailed: true });
        }

        return possibilities;
    }

    setBotGuess(guess) {
        guess = guess.toLowerCase();
        let fullGuess = [];
        for (let i = 0; i < guess.length; i++) {
            fullGuess.push({ letter: guess[i], correctIndex: null, correctLetter: null });
        }

        return fullGuess;
    }

    submitBotGuess(allGuesses) {
        const guess = allGuesses[allGuesses.length - 1];
        guess.forEach((guessLetter, idx) => {
            guessLetter.submitted = true;
            if (correctWord.includes(guessLetter.letter)) {
                if (correctWord[idx] === guessLetter.letter) {
                    guessLetter.correctLetter = true;
                    guessLetter.correctIndex = true;
                } else if (this.letterElsewhereAndUnusedBot(guess, guessLetter.letter, idx)) { //don't mark second p in guess 'pulpy' as yellow just because the first letter is 'p' (since we already got it)
                    guessLetter.correctLetter = true;
                    guessLetter.correctIndex = false;
                }
            }
            guessLetter.officialLetter = guessLetter.letter;
            guessLetter.letter = ''; //do not show the letter on screen or in dev tools
        })
        const res = [...this.state.botGuesses].concat([guess])
        this.setState({ botGuesses: res });
        this.props.storeBotGuesses(res)
    }

    letterElsewhereAndUnusedBot(guess, letter, idx) {
        const re = new RegExp(letter, "g");
        if (correctWord.match(re).length === 1) { return !guess.find(g => g.officialLetter === letter && g.correctLetter) && (guess[correctWord.indexOf(letter)].officialLetter || guess[correctWord.indexOf(letter)].letter) !== letter; }
        else {
            let indexes = [];
            for (let i = 0; i < correctWord.length; i++) {
                if (correctWord[i] === letter) { indexes.push(i); }
            }

            let letterElsewhere = false;
            indexes.forEach(index => {
                if (idx !== index) {
                    if (guess.map(g => g.officialLetter || g.letter).join('')[index] !== letter) { letterElsewhere = true; return; }
                }
            })

            return letterElsewhere;
        }
    }

    letterElsewhereAndUnusedUser(guess, letter, idx) {
        const re = new RegExp(letter.toLowerCase(), "g");
        if (correctWord.match(re).length === 1) { return !guess.find(g => g.letter.toLowerCase() === letter.toLowerCase() && g.correctLetter) && guess[correctWord.indexOf(letter.toLowerCase())].letter.toLowerCase() !== letter.toLowerCase(); }
        else if (guess.find(g => g.letter.toLowerCase() === letter.toLowerCase() && !g.correctIndex && g.correctLetter)) { return false; }
        else {
            let indexes = [];
            for (let i = 0; i < correctWord.length; i++) {
                if (correctWord[i] === letter.toLowerCase()) { indexes.push(i); }
            }

            let letterElsewhere = false;
            indexes.forEach(index => {
                if (idx !== index) {
                    if (guess.map(g => g.letter.toLowerCase()).join('')[index] !== letter.toLowerCase()) { letterElsewhere = true; return; }
                }
            })

            return letterElsewhere;
        }
    }

    clickLetter(letter) {
        if (this.state.actionsAllowed || !this.props.began) { // before it starts or during, but not after
            if (!this.props.began) { // start if it hasn't
                this.props.start(null, true);
            }
            let newGuesses = [...this.state.guesses];
            let latestGuess = newGuesses[newGuesses.length - 1]
            if (latestGuess.length < 5) {
                latestGuess.push({ letter: letter, correctIndex: null, correctLetter: null });
                this.setState({ guesses: newGuesses });
            }
        }
    }

    deleteLetter() {
        if (this.props.began && this.state.actionsAllowed) {
            let newGuesses = [...this.state.guesses];
            let latestGuess = newGuesses[newGuesses.length - 1]
            if (latestGuess.length > 0) {
                latestGuess.splice(latestGuess.length - 1, 1)
                this.setState({ invalidWord: false });
                this.setState({ guesses: newGuesses });
            }
        }
    }

    handleEnter() {
        if (this.props.began && this.state.actionsAllowed && this.state.guesses.length <= 6) {
            let newGuesses = [...this.state.guesses];
            const latestGuess = newGuesses[newGuesses.length - 1];
            if (latestGuess.length === 5) {
                const latestWord = latestGuess.map(guess => guess.letter.toLowerCase()).join('');
                if (!fullDictionary.includes(latestWord)) {
                    this.setState({ invalidWord: true });
                    delay(3000).then(() => {
                        this.setState({ invalidWord: false });
                    })
                    return;
                }
                latestGuess.forEach((guessLetter, idx) => {
                    const lowercaseLetter = guessLetter.letter.toLowerCase();
                    guessLetter.submitted = true;
                    if (correctWord.includes(lowercaseLetter)) {
                        if (correctWord[idx] === lowercaseLetter) {
                            guessLetter.correctLetter = true;
                            guessLetter.correctIndex = true;
                        } else if (this.letterElsewhereAndUnusedUser(latestGuess, guessLetter.letter, idx)) { //don't mark second p in guess 'pulpy' as yellow just because the first letter is 'p' (since we already got it)
                            guessLetter.correctLetter = true;
                            guessLetter.correctIndex = false;
                        }
                    }
                })
                newGuesses.push([])
                this.setState({ guesses: newGuesses });
                this.userGuessedCorrectly(latestGuess);
                this.handleKeyboard(latestGuess);
            }
        }
    }

    handleKeyboard(latestGuess) {
        const incorrectLetters = latestGuess.filter(guess => !guess.correctLetter && !latestGuess.find(letter => letter.letter === guess.letter && (letter.correctIndex || letter.correctLetter))).map(guess => guess.letter);
        const correctLettersWrongPosition = latestGuess.filter(guess => guess.correctLetter && !guess.correctIndex).map(guess => guess.letter);
        const correctIndexLetters = latestGuess.filter(guess => guess.correctIndex).map(guess => guess.letter);
        this.setState({ incorrectLetters: this.state.incorrectLetters + incorrectLetters });
        this.setState({ correctLetterWrongPosition: this.state.correctLetterWrongPosition + correctLettersWrongPosition });
        this.setState({ correctIndexLetters: this.state.correctIndexLetters + correctIndexLetters });
    }

    userGuessedCorrectly(userGuess) {
        const guess = userGuess.map(guess => guess.letter).join('');
        const res = guess.toLowerCase() === correctWord;
        if (res) { this.props.setUserWinner(true) }
        return res;
    }

    getText() {
        let text = 'Droidle%0D%0A%0D%0AMe:%0D%0A%0D%0A'
        const guesses = [...this.state.guesses];
        const submittedGuesses = guesses.filter(g => g.find(letter => letter.submitted));
        submittedGuesses.forEach(guess => {
            guess.forEach(letter => {
                if (letter.correctIndex) { text += '🟩' }
                else if (letter.correctLetter) { text += '🟨' }
                else { text += '⬛️' }
            })
            text += "%0D%0A"
        })

        text += "%0D%0A%0D%0A";
        text += this.props.selectedDifficulty.name + " (" + this.props.selectedDifficulty.difficulty + "):";
        text += "%0D%0A%0D%0A";

        const botGuesses = [...this.props.botGuesses];
        const submittedBotGuesses = botGuesses.filter(g => g.find(letter => letter.submitted));
        submittedBotGuesses.forEach((guess, idx) => {
            guess.forEach(letter => {
                if (letter.correctIndex) { text += '🟩' }
                else if (letter.correctLetter) { text += '🟨' }
                else { text += '⬛️' }
            })
            if (idx !== submittedBotGuesses.length - 1) {
                text += "%0D%0A"
            }
        })


        return text;
    }

    render() {
        return (
            <>
                {this.props.user && !this.props.began && <button className="btn btn-lg btn-primary start" onClick={this.props.start} ref={this.startRef}>START</button>}
                <div className={`droidle-container ${this.props.winnerDecided ? (this.props.user ? (this.props.botWon ? 'loser' : 'winner') : (this.props.botWon ? 'winner' : 'loser')) : ''}`}>
                    {this.props.user && <h1 className={this.props.winnerDecided && this.props.botWon ? 'defeat' : this.props.winnerDecided && !this.props.botWon ? 'success' : ''}>You</h1>}
                    {!this.props.user && <div className="name-container"><h1 className={this.props.winnerDecided && this.props.botWon ? 'success' : this.props.winnerDecided && !this.props.botWon ? 'defeat' : ''}>{this.props.selectedDifficulty.name}</h1><span>  ({this.props.selectedDifficulty.difficulty.toUpperCase()})</span></div>}
                    <div className="board-container">
                        <div className="board">
                            <div>
                                {
                                    [...Array(6)].map((_, idx) =>
                                        <DroidleRow user={this.props.user} key={idx} guess={this.props.user ? this.state.guesses[idx] : this.state.botGuesses[idx]} winnerDecided={this.props.winnerDecided} />
                                    )
                                }
                            </div>
                            {this.props.user && this.state.invalidWord && <h2 className="defeat">Invalid Word</h2>}
                        </div>
                        <Keyboard user={this.props.user} keys={keys} began={this.props.began} actionsAllowed={this.state.actionsAllowed} clickLetter={this.clickLetter} deleteLetter={this.deleteLetter} guesses={this.state.guesses} handleEnter={this.handleEnter} incorrectLetters={this.state.incorrectLetters} correctLettersWrongPosition={this.state.correctLetterWrongPosition} correctIndexLetters={this.state.correctIndexLetters} />
                    </div>
                </div>
                {this.props.user && this.props.winnerDecided && this.state.operatingSystem === IOS && <a className="btn btn-lg btn-primary share" href={"sms:?&body=" + this.getText()}>Share</a>}
                {this.props.user && this.props.winnerDecided && this.state.operatingSystem === ANDROID && <a className="btn btn-lg btn-primary share" href={"sms:?body=" + this.getText()}>Share</a>}
            </>
        );
    }
}

export default OneVersusOneMatch;
