import React, { useEffect, useState } from 'react';
import OneVersusOneMatch from './one-vs-one-match';
import { connect } from 'react-redux';

const OneVersusOneContainer = (props) => {
    const [botWon, setBotWon] = useState(false);
    const [userWon, setUserWon] = useState(false);
    const [winnerDecided, setWinnerDecided] = useState(false);
    const [began, setBegan] = useState(false);
    const [botGuesses, setBotGuesses] = useState([[]])

    const setBotWinner = (win) => {
        setBotWon(win);
        setWinnerDecided(win);
    }

    const setUserWinner = (win) => {
        setUserWon(win);
        setWinnerDecided(win);
    }

    useEffect(() => { }, [botWon, userWon, winnerDecided])

    const resetForm = () => {
        window.location.reload();
    }

    const start = (e, runWithoutEvent) => {
        if (runWithoutEvent || (e && (e.type === "click" || (!e.ctrlKey && !e.altKey && !e.metaKey && ((e.keyCode >= 48 && e.keyCode <= 90) || (e.keyCode >= 97 && e.keyCode <= 122)))))) {
            setBegan(true);
            window.removeEventListener('keypress', start);
        }
    }

    const storeBotGuesses = (guesses) => {
        setBotGuesses(guesses);
    }

    return (
        <div>
            <div className="refresh-container">
                <button className="btn btn-light refresh-button" type="button" onClick={resetForm}>Refresh</button>
            </div>
            <div className="droidle-match double">
                <OneVersusOneMatch user={true} botGuesses={botGuesses} began={began} selectedDifficulty={props.selectedDifficulty} start={start} setUserWinner={setUserWinner} userWon={userWon} botWon={botWon} winnerDecided={winnerDecided} />
                <OneVersusOneMatch user={false} began={began} storeBotGuesses={storeBotGuesses} selectedDifficulty={props.selectedDifficulty} winnerDecided={winnerDecided} userWon={userWon} setBotWinner={setBotWinner} botWon={botWon} />
            </div>
        </div>
    );

}
export default connect()(OneVersusOneContainer);
