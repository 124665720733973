import React, { useState } from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/home/home';
import './custom.css'
import OneVersusOneContainer from './components/one-vs-one/one-vs-one-container';
import OutsmartDroidleContainer from './components/outsmart/outsmart-droidle-container';

const difficulties = [
    { difficulty: 'easy', name: 'Dot', color: 'pink', sizeFactor: .1 },
    { difficulty: 'medium', name: 'Ria', eyeColor: '#ffc700', sizeFactor: .2 },
    { difficulty: 'hard', name: 'Otis', color: '#8300ff', eyeColor: 'red', sizeFactor: .3 }
]

const userChosenDifficulty = localStorage.getItem('difficulty');

const App = (props: any) => {
    const [difficulty, setDifficulty] = useState(userChosenDifficulty ? difficulties.find(diff => diff.difficulty === userChosenDifficulty) : difficulties[0]);

    const changeDifficulty = (difficulty: any) => {
        localStorage.setItem('difficulty', difficulty.difficulty);
        setDifficulty(difficulty);
    }

    return (
        <Layout>
            <Route exact path='/' render={() => <Home selectedDifficulty={difficulty} difficulties={difficulties} changeDifficulty={changeDifficulty} />} />
            <Route exact path='/one-vs-one' render={() => <OneVersusOneContainer selectedDifficulty={difficulty} />} />
            <Route exact path='/outsmart-droidle' render={() => <OutsmartDroidleContainer selectedDifficulty={difficulty} />} />
        </Layout >
    );

}
export default App;