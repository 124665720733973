import React, { useEffect, useState } from 'react';
import OutsmartDroidleMatch from './outsmart-droidle-match';
import { connect } from 'react-redux';

const OutsmartDroidleContainer = (props) => {
    const [winnerDecided, setWinnerDecided] = useState(false);

    useEffect(() => { }, [winnerDecided])

    const resetForm = (e) => {
        if (e.type === 'click' || e.keyCode === 13) {
            window.location.reload();
        }
    }

    return (
        <div>
            <div className="refresh-container">
                <button className="btn btn-light refresh-button" type="button" onClick={resetForm}>Refresh</button>
            </div>
            <div className="droidle-match single">
                <OutsmartDroidleMatch user={false} selectedDifficulty={props.selectedDifficulty} resetForm={resetForm} winnerDecided={winnerDecided} />
            </div>
        </div>
    );

}
export default connect()(OutsmartDroidleContainer);
