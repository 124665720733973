import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Robot from '../parts/robot';

const Home = (props) => {

    const easyDroidle = props.difficulties.find(diff => diff.difficulty === 'easy');
    const mediumDroidle = props.difficulties.find(diff => diff.difficulty === 'medium');
    const hardDroidle = props.difficulties.find(diff => diff.difficulty === 'hard');

    return (
        <>
            <div className="home-wrapper">
                <div className="robots">
                    <Robot multiply={easyDroidle.sizeFactor} resize name={easyDroidle.name} color={easyDroidle.color} difficulty="easy" isSetDifficulty={props.selectedDifficulty.difficulty === "easy"} onClick={() => props.changeDifficulty(easyDroidle)} />
                    <Robot multiply={mediumDroidle.sizeFactor} resize name={mediumDroidle.name} eyeColor={mediumDroidle.eyeColor} difficulty="medium" isSetDifficulty={props.selectedDifficulty.difficulty === "medium"} onClick={() => props.changeDifficulty(mediumDroidle)} />
                    <Robot multiply={hardDroidle.sizeFactor} resize name={hardDroidle.name} color={hardDroidle.color} eyeColor={hardDroidle.eyeColor} difficulty="hard" isSetDifficulty={props.selectedDifficulty.difficulty === "hard"} onClick={() => props.changeDifficulty(hardDroidle)} />
                </div>
                <div className="grid">
                    <p>Want to battle a Droidle?<br />You have two options...</p>
                    <p><span>You can try to <Link to="/outsmart-droidle">outsmart a Droidle</Link> by thinking of a word it can't guess...</span><br /><span>or you can try to beat Droidle <Link to="/one-vs-one">one vs one</Link> at its own game.</span></p>
                </div>
            </div>
            <div className="home-footer">
                <h4 className="developed-by">Created by <a target="blank" rel="noreferrer" href="https://cameronjones.tech">Cam Jones</a></h4>
            </div>
        </>
    );

}
export default connect()(Home);
